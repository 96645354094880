export const environment = {
    production: false,
    msalConfig: {
        auth: {
            clientId: '6bb10a6d-c4b1-4644-8294-9f75239b13d0',
            authority: 'https://login.microsoftonline.com/d8d0ad3e-8bcf-48e9-9bb2-aee17c6c4fd5',
        }
    },
    apiConfig: {
        scopes: ['https://taxus.set-dev.flixbus.com/Taxus.UserAccess']
    },
    apiBaseUrl: () => {
        let baseUrl;
        const host = window.location.host;
        if (/^(feature|bugfix|hotfix|master)-[0-9a-z\\-]+\./.test(host)) {
            const branch = host.split(".", 1)[0];
            baseUrl = `https://taxus-api-${branch}-finance-settlr-dev-public.ew1d1.k8s-dev.flix.tech/api`
        } else {
            baseUrl = 'https://taxus-api.set-dev.flixbus.com/api'
        }
        return baseUrl;
    }
};
