import {Component, OnInit} from '@angular/core';
import {MsalService} from '@azure/msal-angular';
import {Logger} from '@azure/msal-browser';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {environment} from '../environments/environment';
import {HeaderComponent} from './shared/component/header/header.component';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [CommonModule, RouterModule, HeaderComponent]
})
export class AppComponent implements OnInit {
    constructor(private authService: MsalService) {
    }

    ngOnInit() {
        if (!environment.production) {
            this.authService.setLogger(new Logger({
                loggerCallback: (_level, message, containsPii) => {
                    if (containsPii) {
                        console.log('MSAL Logging: ######(PII)');
                    } else {
                        console.log('MSAL Logging: ', message);
                    }
                }
            }));
        }
    }
}
